document.addEventListener('DOMContentLoaded', function () {
  const languageSwitchers = document.querySelectorAll<HTMLElement>('[data-language-switcher]');

  languageSwitchers.forEach(switcher => {
    const currentLangBtn = switcher.querySelector<HTMLButtonElement>('.current-lang-btn');
    let dropdown: HTMLElement | null = null;
    
    const calculateDropdownPosition = () => {
      if (currentLangBtn && dropdown) {
        const rect = currentLangBtn.getBoundingClientRect();
        dropdown.style.left = `${rect.left - 24}px`;
      }
    };

    const hideDropdown = () => {
      if (dropdown) {
        dropdown.remove();
        dropdown = null;
        currentLangBtn?.setAttribute('aria-expanded', 'false');
      }
    };

    const toggleDropdown = () => {
      if (dropdown) {
        hideDropdown();
      } else {
        dropdown = document.createElement('div');
        dropdown.className = 'language-dropdown';
        
        const otherLangUrl = switcher.getAttribute('data-other-lang-url') || '';
        const otherLang = switcher.getAttribute('data-other-lang') || '';
        
        dropdown.innerHTML = `
          <a href="${otherLangUrl}">
            ${otherLang}
          </a>
        `;
        
        document.body.appendChild(dropdown);
        calculateDropdownPosition();
        currentLangBtn?.setAttribute('aria-expanded', 'true');
      }
    };

    currentLangBtn?.addEventListener('click', (e) => {
      e.preventDefault();
      toggleDropdown();
    });

    document.addEventListener('click', (e) => {
      if (dropdown && !switcher.contains(e.target as Node)) {
        hideDropdown();
      }
    });

    window.addEventListener('scroll', hideDropdown);

    window.addEventListener('resize', () => {
      hideDropdown();
      calculateDropdownPosition();
    });

    window.addEventListener('orientationchange', hideDropdown);
  });
});
interface ContactFormFields {
    salutation: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    message: string;
    arrival: string;
    departure: string;
    privacy: boolean;
    bookingType: 'hotel' | 'event';
}

interface ErrorMessages {
    arrival_required: string;
    arrival_future: string;
    departure_required: string;
    departure_after_arrival: string;
    salutation_required: string;
    firstname_required: string;
    lastname_required: string;
    email_required: string;
    email_invalid: string;
    privacy_required: string;
}

document.addEventListener("DOMContentLoaded", () => {
    const contactFormContainer = document.querySelector("[data-init-contactform]");

    if (contactFormContainer instanceof HTMLFormElement) {
        const errorMessagesAttr = contactFormContainer.querySelector('[data-error-messages]')?.getAttribute('data-error-messages');
        const errorMessages: ErrorMessages = errorMessagesAttr ? JSON.parse(errorMessagesAttr) : {};

        const formFields: ContactFormFields = {
            salutation: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            message: '',
            arrival: '',
            departure: '',
            privacy: false,
            bookingType: 'hotel'
        };

        let formSubmitted = false;

        const radioButtons = contactFormContainer.querySelectorAll<HTMLInputElement>('input[type="radio"][name="booking-type"]');
        const departureField = contactFormContainer.querySelector<HTMLElement>('.departure-field');
        const arrivalInput = contactFormContainer.querySelector<HTMLInputElement>('input[name="arrival"]');
        const departureInput = contactFormContainer.querySelector<HTMLInputElement>('input[name="departure"]');

        const getMinDate = (): string => {
            const tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            return tomorrow.toISOString().split('T')[0];
        };

        const setupDateValidation = () => {
            if (arrivalInput) {
                const minDate = getMinDate();
                arrivalInput.min = minDate;

                arrivalInput.addEventListener('change', () => {
                    if (departureInput && arrivalInput.value) {
                        departureInput.min = arrivalInput.value;

                        if (departureInput.value && departureInput.value < arrivalInput.value) {
                            departureInput.value = '';
                        }
                    }
                });
            }

            if (departureInput) {
                departureInput.min = getMinDate();
            }
        };

        const setFieldError = (field: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, errorMessage: string): void => {
            const parentElement = field.closest('.form-element');
            if (!parentElement) return;

            const errorElement = parentElement.querySelector('.form-element__error-message');
            if (!errorElement) return;

            const errorId = `${field.name}-error`;
            errorElement.id = errorId;

            if (errorMessage) {
                parentElement.classList.add('form-element--error');
                parentElement.setAttribute('tabindex', '-1');
                errorElement.textContent = errorMessage;
                errorElement.removeAttribute('hidden');
                field.setAttribute('aria-invalid', 'true');
                field.setAttribute('aria-describedby', errorId);
            } else {
                parentElement.classList.remove('form-element--error');
                parentElement.removeAttribute('tabindex');
                errorElement.setAttribute('hidden', '');
                field.removeAttribute('aria-invalid');
                field.removeAttribute('aria-describedby');
            }
        };

        const toggleDepartureField = (showDeparture: boolean): void => {
            if (!departureField) return;

            if (showDeparture) {
                departureField.classList.remove('hidden');
                const departureInput = departureField.querySelector<HTMLInputElement>('input');
                if (departureInput) {
                    departureInput.required = true;
                }
            } else {
                departureField.classList.add('hidden');
                const departureInput = departureField.querySelector<HTMLInputElement>('input');
                if (departureInput) {
                    departureInput.required = false;
                    departureInput.value = '';
                    setFieldError(departureInput, '');
                }
            }
        };

        const validateField = (field: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement): boolean => {
            const fieldName = field.name as keyof ContactFormFields;
            const value = field.type === 'checkbox' ? (field as HTMLInputElement).checked : field.value.trim();
            const minDate = getMinDate();

            if (fieldName === 'bookingType') {
                if (value === 'hotel' || value === 'event') {
                    formFields.bookingType = value;
                }
            } else if (fieldName === 'privacy') {
                formFields[fieldName] = value as boolean;
            } else {
                (formFields[fieldName] as string) = value as string;
            }

            let isValid = true;
            let errorMessage = '';

            switch (fieldName) {
                case 'arrival':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.arrival_required;
                    } else if (value < minDate) {
                        isValid = false;
                        errorMessage = errorMessages.arrival_future;
                    }
                    break;
                case 'departure':
                    const isHotelBooking = formFields.bookingType === 'hotel';
                    if (isHotelBooking && !departureField?.classList.contains('hidden')) {
                        if (!value) {
                            isValid = false;
                            errorMessage = errorMessages.departure_required;
                        } else if (formFields.arrival && value <= formFields.arrival) {
                            isValid = false;
                            errorMessage = errorMessages.departure_after_arrival;
                        }
                    }
                    break;
                case 'salutation':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.salutation_required;
                    }
                    break;
                case 'firstname':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.firstname_required;
                    }
                    break;
                case 'lastname':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.lastname_required;
                    }
                    break;
                case 'email':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.email_required;
                    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value as string)) {
                        isValid = false;
                        errorMessage = errorMessages.email_invalid;
                    }
                    break;
                case 'privacy':
                    if (!value) {
                        isValid = false;
                        errorMessage = errorMessages.privacy_required;
                    }
                    break;
            }

            setFieldError(field, errorMessage);
            return isValid;
        };

        setupDateValidation();

        const addInputListeners = () => {
            contactFormContainer.querySelectorAll<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
                'input:not([type="radio"]), textarea, select'
            ).forEach(field => {
                if (!field.dataset.hasInputListener) {
                    field.addEventListener('input', () => {
                        validateField(field);
                    });
                    field.dataset.hasInputListener = 'true';
                }
            });
        };

        const validateForm = (event: Event): void => {
            event.preventDefault();

            let isValid = true;
            const fields = contactFormContainer.querySelectorAll<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
                'input:not([type="radio"]), textarea, select'
            );

            fields.forEach((field) => {
                if (!validateField(field)) {
                    isValid = false;
                }
            });

            if (isValid) {
                const bookingTypeInput = document.createElement('input');
                bookingTypeInput.type = 'hidden';
                bookingTypeInput.name = 'bookingType';
                bookingTypeInput.value = formFields.bookingType;
                contactFormContainer.appendChild(bookingTypeInput);

                contactFormContainer.submit();
            } else {
                if (!formSubmitted) {
                    formSubmitted = true;
                    addInputListeners();
                }
                const firstInvalidField = contactFormContainer.querySelector<HTMLElement>(
                    '.form-element--error input, .form-element--error textarea, .form-element--error select'
                );
                if (firstInvalidField) {
                    firstInvalidField.focus();
                }
            }
        };

        contactFormContainer.querySelectorAll<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>(
            'input:not([type="radio"]), textarea, select'
        ).forEach(field => {
            field.addEventListener('blur', () => {
                validateField(field);
                if (!field.dataset.hasInputListener) {
                    field.addEventListener('input', () => {
                        validateField(field);
                    });
                    field.dataset.hasInputListener = 'true';
                }
            });
        });

        radioButtons.forEach((radio) => {
            radio.addEventListener('change', (event) => {
                const target = event.target as HTMLInputElement;
                const isHotel = target.value === 'hotel';
                toggleDepartureField(isHotel);
                formFields.bookingType = target.value as 'hotel' | 'event';
                
                // Update arrival label based on booking type
                const arrivalLabel = contactFormContainer.querySelector<HTMLElement>('input[name="arrival"]')
                    ?.closest('.form-element')
                    ?.querySelector('[data-hotel-label]');
                    
                if (arrivalLabel) {
                    const label = isHotel 
                        ? arrivalLabel.getAttribute('data-hotel-label')
                        : arrivalLabel.getAttribute('data-event-label');
                    arrivalLabel.textContent = label + (isHotel ? ' *' : ' *');
                }
            });
        });

        toggleDepartureField(true);
        contactFormContainer.addEventListener('submit', validateForm);
    }

    document.addEventListener('mousedown', () => {
        document.body.classList.add('using-mouse');
    });

    document.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
            document.body.classList.remove('using-mouse');
        }
    });
});
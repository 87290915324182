document.addEventListener('DOMContentLoaded', () => {
  const scrollElements = document.querySelectorAll<HTMLElement>('[data-scroll-hide]');
  
  function handleScroll() {
      scrollElements.forEach((element: HTMLElement) => {
          const rect = element.getBoundingClientRect();
          const scrollTop = window.scrollY;
          const elementTop = rect.top + scrollTop;
          const elementHeight = rect.height;
          const fadeEnd = elementTop + elementHeight - 150;
          
          let opacity = 1 - (scrollTop / fadeEnd);
          
          opacity = Math.max(0, Math.min(1, opacity));
          
          element.style.opacity = opacity.toString();
      });
  }

  handleScroll();
  
  window.addEventListener('scroll', handleScroll, { passive: true });
});
interface Slideshow {
    element: HTMLElement;
    controls: NodeListOf<HTMLButtonElement>;
    currentIndex: number;
    intervalId: number | null;
    isTransitioning: boolean;
}

document.addEventListener('DOMContentLoaded', () => {
    const slideshowElements = document.querySelectorAll<HTMLElement>('[data-init-slideshow]');
    if (!slideshowElements.length) return;

    slideshowElements.forEach(element => initializeSlideshow(element));
});

function initializeSlideshow(element: HTMLElement): void {
    const slideshow: Slideshow = {
        element,
        controls: element.querySelectorAll<HTMLButtonElement>('[data-slide-index]'),
        currentIndex: 0,
        intervalId: null,
        isTransitioning: false
    };

    // Set initial image
    const initialImageUrl = slideshow.controls[0].dataset.slideUrl;
    if (initialImageUrl) {
        slideshow.element.style.setProperty('--current-image', `url(${initialImageUrl})`);
    }

    function startSlideshow(): void {
        if (slideshow.intervalId) {
            clearInterval(slideshow.intervalId);
        }

        slideshow.intervalId = window.setInterval(() => {
            const nextIndex = (slideshow.currentIndex + 1) % slideshow.controls.length;
            showSlide(nextIndex);
        }, 60000);
    }

    function showSlide(index: number): void {
        if (slideshow.isTransitioning) return;
        slideshow.isTransitioning = true;

        // Update controls
        slideshow.controls.forEach((control, i) => {
            control.classList.toggle('is-active', i === index);
        });

        // Get next image URL
        const nextImageUrl = slideshow.controls[index].dataset.slideUrl;
        if (!nextImageUrl) return;

        // Set up next transition
        slideshow.element.style.setProperty('--next-image', `url(${nextImageUrl})`);
        slideshow.element.classList.add('is-transitioning');

        // After transition completes
        setTimeout(() => {
            slideshow.element.style.setProperty('--current-image', `url(${nextImageUrl})`);
            slideshow.element.classList.remove('is-transitioning');
            slideshow.currentIndex = index;
            slideshow.isTransitioning = false;
        }, 600);

        // Restart slideshow timer
        startSlideshow();
    }

    // Add click handlers to controls
    slideshow.controls.forEach(control => {
        control.addEventListener('click', () => {
            const targetIndex = parseInt(control.dataset.slideIndex ?? '0', 10);
            if (targetIndex !== slideshow.currentIndex) {
                showSlide(targetIndex);
            }
        });
    });

    // Start initial slideshow
    startSlideshow();

    // Handle visibility change
    document.addEventListener('visibilitychange', () => {
        if (document.hidden) {
            if (slideshow.intervalId) {
                clearInterval(slideshow.intervalId);
                slideshow.intervalId = null;
            }
        } else {
            startSlideshow();
        }
    });
}
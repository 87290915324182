document.addEventListener('DOMContentLoaded', () => {
  const backToTopButton = document.querySelector('[data-init-back-to-top]') as HTMLAnchorElement;

  if (backToTopButton) {
    const showButtonThreshold = 1000;
    const targetElementId = backToTopButton.getAttribute('href')?.slice(1);
    const targetElement = targetElementId ? document.getElementById(targetElementId) : null;

    const toggleButtonVisibility = () => {
      if (window.scrollY > showButtonThreshold) {
        backToTopButton.style.opacity = '1';
        backToTopButton.style.pointerEvents = 'auto';
        backToTopButton.tabIndex = 0;
      } else {
        backToTopButton.style.opacity = '0';
        backToTopButton.style.pointerEvents = 'none';
        backToTopButton.tabIndex = -1;
      }
    };

    const scrollToTarget = (e: Event) => {
      e.preventDefault();
      if (targetElement) {

        if (targetElement.tabIndex < 0) {
          targetElement.tabIndex = -1;
        }

        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        
        requestAnimationFrame(() => {
          targetElement.focus({ preventScroll: true });
        });

        targetElement.classList.add('focus-visible');
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };

    toggleButtonVisibility();

    window.addEventListener('scroll', toggleButtonVisibility);
    backToTopButton.addEventListener('click', scrollToTarget);

    backToTopButton.removeAttribute('data-init-back-to-top');
  }
});
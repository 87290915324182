import { initCircledFocus } from '../utils/circled-focus';

document.addEventListener('DOMContentLoaded', function () {
    const headerMenu = document.querySelector('[data-init-headermenu]');

    if (headerMenu) {
        const menuButton = headerMenu.querySelector('[data-menu-toggle]') as HTMLElement;
        const menuItems = document.querySelector('.header__menu-items') as HTMLElement;
        const closeButton = document.querySelector('.header__menu-close') as HTMLElement;
        const dropdownItems = menuItems.querySelectorAll('[data-has-dropdown]');

        let removeCircledFocus: (() => void) | null = null;

        const showMenu = () => {
            document.body.style.overflow = 'hidden';
            menuItems.style.visibility = 'visible';
            requestAnimationFrame(() => {
                menuItems.classList.add('visible');
            });
            menuButton.setAttribute('aria-expanded', 'true');
            removeCircledFocus = initCircledFocus(menuItems);
        };

        const hideMenu = () => {
            document.body.style.overflow = '';
            menuItems.classList.remove('visible');
            menuButton.setAttribute('aria-expanded', 'false');
            
            setTimeout(() => {
                menuItems.style.visibility = 'hidden';
            }, 300);

            if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }

            dropdownItems.forEach(closeDropdown);
        };

        const cleanupMenuState = () => {
            document.body.style.overflow = '';
            menuItems.classList.remove('visible');
            menuItems.style.visibility = 'hidden';
            menuButton.setAttribute('aria-expanded', 'false');
            if (removeCircledFocus) {
                removeCircledFocus();
                removeCircledFocus = null;
            }
            dropdownItems.forEach(closeDropdown);
        };

        window.addEventListener('pagehide', cleanupMenuState);
        window.addEventListener('beforeunload', cleanupMenuState);

        const closeDropdown = (item: Element) => {
            const dropdownToggle = item.querySelector('.dropdown-toggle') as HTMLElement;
            const dropdownMenu = item.querySelector('.dropdown') as HTMLElement;
            if (dropdownToggle && dropdownMenu) {
                item.classList.remove('open');
                dropdownToggle.setAttribute('aria-expanded', 'false');
                dropdownMenu.setAttribute('aria-hidden', 'true');
                dropdownMenu.style.display = 'none';
            }
        };

        const openDropdown = (item: Element) => {
            const dropdownToggle = item.querySelector('.dropdown-toggle') as HTMLElement;
            const dropdownMenu = item.querySelector('.dropdown') as HTMLElement;
            if (dropdownToggle && dropdownMenu) {
                item.classList.add('open');
                dropdownToggle.setAttribute('aria-expanded', 'true');
                dropdownMenu.setAttribute('aria-hidden', 'false');
                dropdownMenu.style.display = 'block';
            }
        };

        menuButton.addEventListener('click', () => {
            const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';
            if (!isExpanded) {
                showMenu();
            } else {
                hideMenu();
            }
        });

        if (closeButton) {
            closeButton.addEventListener('click', hideMenu);
        }

        dropdownItems.forEach(item => {
            const dropdownToggle = item.querySelector('.dropdown-toggle') as HTMLElement;
            
            if (dropdownToggle) {
                dropdownToggle.addEventListener('click', (e) => {
                    e.preventDefault();
                    const isOpen = item.classList.contains('open');

                    dropdownItems.forEach(otherItem => {
                        if (otherItem !== item) {
                            closeDropdown(otherItem);
                        }
                    });

                    if (isOpen) {
                        closeDropdown(item);
                    } else {
                        openDropdown(item);
                    }
                });
            }
        });

        document.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Escape' && menuItems.classList.contains('visible')) {
                const openDropdown = menuItems.querySelector('[data-has-dropdown].open');
                if (openDropdown) {
                    closeDropdown(openDropdown);
                } else {
                    hideMenu();
                    menuButton.focus();
                }
            }
        });

        document.addEventListener('click', (event: MouseEvent) => {
            const target = event.target as Node;
            if (menuItems.classList.contains('visible') && 
                !menuItems.contains(target) && 
                !menuButton.contains(target)) {
                hideMenu();
            }
        });
    }
});
document.addEventListener('DOMContentLoaded', () => {
  const skipLinkElements = document.querySelectorAll<HTMLElement>('[data-init-skip-link]');

  if (skipLinkElements.length > 0) {
      const skipLinkContainer = document.createElement('div');
      skipLinkContainer.className = 'skip-links';

      skipLinkElements.forEach((element) => {
          const targetId = element.id;
          const linkText = element.getAttribute('data-init-skip-link') ?? 'Skip to content';

          const link = document.createElement('a');
          link.href = `#${targetId}`;
          link.textContent = linkText;
          link.className = 'btn btn--inline skip-links__link';

          skipLinkContainer.appendChild(link);
      });

      if (skipLinkContainer.children.length > 0) {
          document.body.insertBefore(skipLinkContainer, document.body.firstChild);
      }
  }
});

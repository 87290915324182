document.addEventListener('DOMContentLoaded', () => {
    const header = document.querySelector('.header');
    const body = document.body;
    if (!header) return;
    
    if (window.scrollY > 50) {
        body.classList.add('scrolled');
    }
    
    setTimeout(() => {
        header.classList.remove('no-transition');
    }, 300);
    
    const handleScroll = () => {
        body.classList.toggle('scrolled', window.scrollY > 50);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
  });
interface GalleryImage {
  url: string;
  alt: string;
}

document.addEventListener('DOMContentLoaded', () => {
  const galleryPopup = document.querySelector('.gallery-popup') as HTMLElement;
  if (!galleryPopup) return;

  const galleryImageContainer = document.createElement('div');
  galleryImageContainer.className = 'gallery-popup__image-container';
  const galleryContent = galleryPopup.querySelector('.gallery-popup__content') as HTMLElement;
  const galleryImage = galleryPopup.querySelector('.gallery-popup__image') as HTMLImageElement;
  galleryContent.insertBefore(galleryImageContainer, galleryImage);
  galleryImage.style.display = 'none';

  const prevButton = galleryPopup.querySelector('.gallery-popup__nav--prev') as HTMLButtonElement;
  const nextButton = galleryPopup.querySelector('.gallery-popup__nav--next') as HTMLButtonElement;
  const closeButton = galleryPopup.querySelector('.gallery-popup__close') as HTMLButtonElement;

  let currentImageIndex = 0;
  let galleryImages: GalleryImage[] = [];
  let openedByKeyboard = false;
  let lastFocusedElement: HTMLElement | null = null;
  let isTransitioning = false;

  const focusableElements = [closeButton, prevButton, nextButton];
  let currentFocusIndex = 0;

  const collectGalleryImages = (): GalleryImage[] => {
    return Array.from(document.querySelectorAll('[data-gallery-image]')).map(element => ({
      url: element.getAttribute('data-image-url') || '',
      alt: element.getAttribute('data-image-alt') || ''
    }));
  };
  
  const preloadImage = (url: string): Promise<void> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve();
      img.src = url;
    });
  };
  
  const openGalleryPopup = async (clickedImageUrl: string, isKeyboardTriggered = false): Promise<void> => {
    galleryImages = collectGalleryImages();
    const initialIndex = galleryImages.findIndex(img => img.url === clickedImageUrl);
    if (initialIndex === -1) return;
    
    openedByKeyboard = isKeyboardTriggered;
    lastFocusedElement = document.activeElement as HTMLElement;

    // Preload initial image before showing popup
    await preloadImage(galleryImages[initialIndex].url);

    // Set initial image without transition
    const initialImage = galleryImages[initialIndex];
    galleryImageContainer.style.setProperty('--current-image', `url(${initialImage.url})`);
    currentImageIndex = initialIndex;

    // Preload next image
    const nextIndex = (initialIndex + 1) % galleryImages.length;
    preloadImage(galleryImages[nextIndex].url);

    galleryPopup.classList.add('is-active');
    galleryPopup.setAttribute('aria-hidden', 'false');
    
    if (openedByKeyboard) {
      closeButton.classList.add('focus-visible');
    }
    closeButton.focus();
    currentFocusIndex = 0;

    document.addEventListener('keydown', handleTabKey);
  };

  const closeGalleryPopup = (): void => {
    galleryPopup.classList.remove('is-active');
    galleryPopup.setAttribute('aria-hidden', 'true');
    closeButton.classList.remove('focus-visible');
    
    if (lastFocusedElement) {
      lastFocusedElement.focus();
    }

    document.removeEventListener('keydown', handleTabKey);
  };

  const updateGalleryImage = (newIndex: number): void => {
    if (isTransitioning) return;
    isTransitioning = true;

    const currentImage = galleryImages[newIndex];
    if (currentImage) {
      // Set up next image
      galleryImageContainer.style.setProperty('--next-image', `url(${currentImage.url})`);
      galleryImageContainer.classList.add('is-transitioning');

      // After transition completes
      setTimeout(() => {
        isTransitioning = false;
        currentImageIndex = newIndex;
        // Update current image but keep the transition class
        galleryImageContainer.style.setProperty('--current-image', `url(${currentImage.url})`);
        
        // Remove transition class in the next frame
        requestAnimationFrame(() => {
          galleryImageContainer.classList.remove('is-transitioning');
        });
      }, 600);

      // Update accessibility attributes
      galleryImageContainer.setAttribute('aria-label', 
        `Image ${currentImageIndex + 1} of ${galleryImages.length}: ${currentImage.alt}`
      );
    }
  };

  const showNextImage = (): void => {
    if (galleryImages.length > 0 && !isTransitioning) {
      const nextIndex = (currentImageIndex + 1) % galleryImages.length;
      updateGalleryImage(nextIndex);
    }
  };

  const showPrevImage = (): void => {
    if (galleryImages.length > 0 && !isTransitioning) {
      const prevIndex = (currentImageIndex - 1 + galleryImages.length) % galleryImages.length;
      updateGalleryImage(prevIndex);
    }
  };

  const handleTabKey = (e: KeyboardEvent): void => {
    if (!galleryPopup.classList.contains('is-active')) return;
    
    if (e.key === 'Tab') {
      e.preventDefault();
      openedByKeyboard = true;

      if (e.shiftKey) {
        currentFocusIndex = (currentFocusIndex - 1 + focusableElements.length) % focusableElements.length;
      } else {
        currentFocusIndex = (currentFocusIndex + 1) % focusableElements.length;
      }

      focusableElements.forEach(el => el.classList.remove('focus-visible'));
      focusableElements[currentFocusIndex].classList.add('focus-visible');
      focusableElements[currentFocusIndex].focus();
    }
  };

  // Add click and keyboard listeners to all gallery images
  document.querySelectorAll<HTMLElement>('[data-gallery-image]').forEach((element) => {
    element.addEventListener('click', () => {
      const imageUrl = element.getAttribute('data-image-url');
      if (imageUrl) {
        openGalleryPopup(imageUrl, false);
      }
    });

    element.addEventListener('keydown', (e: KeyboardEvent) => {
      const imageUrl = element.getAttribute('data-image-url');
      if (!imageUrl) return;

      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        openGalleryPopup(imageUrl, true);
      }
    });
  });

  // Event listeners for popup controls
  closeButton.addEventListener('click', closeGalleryPopup);
  nextButton.addEventListener('click', showNextImage);
  prevButton.addEventListener('click', showPrevImage);

  // Keyboard navigation
  document.addEventListener('keydown', (e: KeyboardEvent) => {
    if (!galleryPopup.classList.contains('is-active')) return;

    switch (e.key) {
      case 'ArrowRight':
        showNextImage();
        break;
      case 'ArrowLeft':
        showPrevImage();
        break;
      case 'Escape':
        closeGalleryPopup();
        break;
    }
  });

  // Touch swipe support
  let touchStartX = 0;
  let touchEndX = 0;
  let isSwiping = false;

  const handleTouchStart = (e: TouchEvent): void => {
    if (isTransitioning) return;
    touchStartX = e.changedTouches[0].screenX;
    isSwiping = true;
  };

  const handleTouchMove = (e: TouchEvent): void => {
    if (!isSwiping) return;
    e.preventDefault(); // Prevent page scrolling while swiping
  };

  const handleTouchEnd = (e: TouchEvent): void => {
    if (!isSwiping) return;
    
    touchEndX = e.changedTouches[0].screenX;
    handleSwipe();
    isSwiping = false;
  };

  const handleSwipe = (): void => {
    const swipeThreshold = 50;
    const swipeDistance = touchEndX - touchStartX;

    if (Math.abs(swipeDistance) < swipeThreshold) return;

    if (swipeDistance > 0) {
      showPrevImage();
    } else {
      showNextImage();
    }
  };

  // Add touch events to the image container
  galleryImageContainer.addEventListener('touchstart', handleTouchStart, { passive: false });
  galleryImageContainer.addEventListener('touchmove', handleTouchMove, { passive: false });
  galleryImageContainer.addEventListener('touchend', handleTouchEnd);
  galleryImageContainer.addEventListener('touchcancel', () => { isSwiping = false; });

});